import { CheckBold } from '@pretto/picto'
import {
  SimulatorType,
  TitleTags,
} from '@pretto/website/src/templates/blocs/components/simulatorRow/SimulatorRow.types'
import { RowSimulator } from '@pretto/website/src/templates/blocs/rows/rowSimulator/components/rowSimulator/RowSimulator'
import { RawTabsData, useSentences } from '@pretto/website/src/templates/blocs/rows/rowSimulator/lib/useSentences'
import type { Promise } from '@pretto/website/src/templates/blocs/types/promisies.type'

export type Tab = { tablabel: string; ctalabel: string }

interface HeroSimulatorContainerProps {
  arePromisesDisplayed: boolean
  projectTypes: SimulatorType[]
  promises: Promise[]
  tabs: RawTabsData
  text: TitleTags
  title: string
}

export const HeroSimulatorContainer: React.FC<HeroSimulatorContainerProps> = ({
  arePromisesDisplayed,
  projectTypes,
  promises,
  tabs,
  text,
  title,
}) => {
  const formatPromises =
    arePromisesDisplayed && promises && promises.length > 0
      ? promises.map(({ promise, title }) => ({ picto: CheckBold, promise, title }))
      : []

  const simulatorProps = useSentences({ tabs: projectTypes, tabsData: tabs })

  const props = {
    isColored: true,
    isHero: true,
    isHeroWithPromises: arePromisesDisplayed && formatPromises.length > 1,
    promises: formatPromises,
    simulatorProps,
    text,
    title,
  }

  return <RowSimulator {...props} />
}
